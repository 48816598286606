<template>
  <div class="container" v-loading.fullscreen.lock="loading">
    <div class="left">
      <div class="header-title">
        课时：{{ lessonInfo }}（共{{ questionList.length || 0 }}道)
      </div>
      <div class="question-list">
        <question-stem
          class="qs"
          v-for="(item, index) in questionList"
          :key="index"
          :value="item"
          :selected="curIndex === index"
          :selectedQuestionId.sync="selectedQuestionId"
          @change="changeSubtopic"
          @select="selectQuestion(item, index)"
          @pageChange="pageChange"
        />
        <div class="nomoredata">没有更多数据了~</div>
      </div>
    </div>
    <div class="right">
      <div class="header-title-btn">
        <div>
          课时：{{ lessonInfo }}（共{{ questionList.length || 0 }}道)
        </div>
        <div>
          <el-button size="small" type="primary" @click="handSave">保存</el-button>
        </div>
      </div>
      <div class="imgArea">
        <SectionPicker
          class="imgBox"
          ref="sectionPicker"
          :lock="false"
          :selectable="false"
          @change="sectionChange"
          @draw="sectionDraw"
        />
        <div class="pageNumBox">
          <ui-pagination
            @current-change="getPageNumUrl"
            :current-page.sync="pageCurrent"
            :pager-count="5"
            :page-list="paginationOption"
            small
            layout="->, prev, pager, next, jumper"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import questionStem from './modules/questionStem.vue'
import SectionPicker from './modules/SectionPicker'
import { getQuestionBankImage, getQuestionListByClassHourId } from '@/api/branchImport.js'
import { questionSaveCoordinate } from '@/api/recordQuestion'

export default {
  components: {
    questionStem,
    SectionPicker
  },
  data () {
    return {
      loading: false,
      curIndex: null, // 当前选中题目index
      questionList: [], // 课时内题目列表
      pageList: [], // 教材页面列表
      lessonInfo: '', // 课时名称

      pageCurrent: 1, // 当前操作页页码
      curPage: null, // 当前操作页
      curSectionList: [], // 当前操作选区 - （当前选中题目选区）

      selectedQuestionId: undefined, // 当前选中题目ID（区分大小题)
    }
  },
  computed: {
    paginationOption () {
      return this.pageList.map(m => ({
        no: m.page,
        url: m.questionImage
      }))
    }
  },
  mounted () {
    this.searchData = this.$store.state.branchImport.searchData
    const stmid = this.searchData.editionGradeValue
    const classHourIds = this.searchData.classHourId
    this.lessonInfo = this.searchData.classHourName

    // const stmid = localStorage.getItem('STMID')
    // const classHourIds = localStorage.getItem('CLASSHOURID')
    // const lessonInfo = this.$nStore.get('lessonInfo')
    // this.lessonInfo = lessonInfo
    Promise.all([
      this.getQuestionListByClassHourId(classHourIds, this.searchData.teachingMaterialId),
      this.getQuestionBankImage(this.searchData.teachingMaterialId, stmid)
    ]).then(() => {
      if (this.pageList.length) {
        // this.$refs.sectionPicker.init(true)
        const params = this.$route.params

        let questionIdx = 0
        if (params.questionId) {
          questionIdx = this.questionList.findIndex(item => item.id === params.questionId)
          questionIdx < 0 && (questionIdx = 0)
        }
        const question = this.questionList[questionIdx]

        let pageNumber = this.$route.query.pageNumber ? Number(this.$route.query.pageNumber) : 1
        if (question && question.coordinate && question.coordinate.length) {
          pageNumber = question.coordinate[0].page
        }
        const pageIdx = this.pageList.findIndex(item => item.page === pageNumber)
        this.curPage = {
          no: this.pageList[pageIdx < 0 ? 0 : pageIdx].page,
          url: this.pageList[pageIdx < 0 ? 0 : pageIdx].questionImage
        }
        if (question) {
          this.selectQuestion(question, questionIdx)
        } else {
          this.getPageNumUrl(this.curPage)
        }
      }
    })
  },
  methods: {
    // 选区变更
    sectionChange (list) {
      if (this.curIndex === null) {
        this.curSectionList = []
        this.getPageNumUrl(this.curPage)
        return this.$message.error('请先选择题目')
      }
      this.curSectionList = this.curSectionList
        .filter(sec => !sec.active || (sec.page !== this.curPage.no)) // 清除旧坐标(当前页且可变更)
        .concat(list.map(m => ({
          active: true,
          page: this.curPage.no,
          data: m.data,
          label: m.data.label,
          coordinate: this.handlerPosition(m.point)
        }))) // 添加新坐标
    },
    // 新增选区
    sectionDraw (shape) {
      console.log('--- sectionDraw', shape)

      if (this.curIndex >= 0) {
        const question = this.questionList[this.curIndex]
        if (shape.$data) {
          const label = this.getQuestionLabel(question, this.selectedQuestionId)
          shape.tipText = label
          shape.$data = {
            qid: this.selectedQuestionId,
            isLabel: question.isLabel,
            label,
          }
        }
      }
    },

    // 当前题目标记页 => 事件冒泡：刷新当前页
    pageChange (pageNum) {
      const page = this.pageList.find(m => m.page === pageNum)
      if (page) {
        this.getPageNumUrl({
          no: page.page,
          url: page.questionImage
        })
      }
    },

    // 选择题目
    selectQuestion (question, index) {
      this.curIndex = index
      this.selectedQuestionId = question.id
      // 更新选区状态
      const list = []
      question.coordinate && question.coordinate.forEach(m => {
        m.coordinate.forEach(coordinate => {
          const label = '大题'
          list.push({
            active: true,
            data: {
              qid: question.id,
              isLabel: question.isLabel,
              label
            },
            // pageId: m.page,
            page: m.page,
            label,
            coordinate
          })
        })
      })
      // 小题选区
      if (question.isCombination && question.subtopicQuestion) {
        question.subtopicQuestion.forEach((sub, subIdx) => {
          sub.coordinate && sub.coordinate.forEach(g => {
            g.coordinate.forEach(coordinate => {
              const label = `第${subIdx + 1}小题`
              list.push({
                active: false,
                data: {
                  qid: sub.questionId,
                  isLabel: sub.isLabel,
                  label
                },
                page: g.page,
                label,
                coordinate
              })
            })
          })
        })
      }
      this.curSectionList = list

      this.getPageNumUrl(this.curPage)
    },

    // 选择大小题
    changeSubtopic (qid) {
      this.curSectionList.forEach(m => {
        m.active = m.data.qid === qid
      })
      this.getPageNumUrl(this.curPage)
    },

    // 根据题目Id获取 label
    getQuestionLabel (question, qid) {
      if (question.id === qid) {
        return '大题'
      }
      if (question.isCombination && question.subtopicQuestion) {
        const idx = question.subtopicQuestion.findIndex(m => m.questionId === qid)
        if (idx >= 0) {
          return `第${idx + 1}小题`
        }
      }
      return ''
    },

    // 根据题目ID获取坐标
    getQuestionCoordinateInfo (sectionList, qid) {
      const m = {}
      sectionList.forEach(section => {
        const page = section.page
        if (section.data.qid === qid && page) {
          if (!m[page]) { m[page] = [] }
          m[page].push(section.coordinate)
        }
      })
      return Object.keys(m).map(page => ({
        page: Number(page),
        coordinate: m[page]
      }))
    },

    // 保存
    handSave () {
      if (this.curIndex === null) {
        return this.$message.error('请修改题目选区后再保存数据')
      }
      if (this.curSectionList.length === 0) {
        return this.$message.error('本题没有指定选区')
      }
      const msg = this.curSectionList.length === 0
        ? '本题没有指定选区，确认是否保存'
        : '是否确认保存当前操作?'
      this.$confirm(msg, '提示').then(() => {
        // const coordinateInfo = this.getQuestionCoordinateInfo(this.curSectionList, question.id)
        // console.log(coordinateInfo)

        // const coordinates = {}
        // this.curSectionList.forEach(section => {
        //   if (section.pageId) {
        //     if (!coordinates[section.pageId]) {
        //       coordinates[section.pageId] = []
        //     }
        //     coordinates[section.pageId].push(section.coordinate)
        //   }
        // })
        console.log('=== ', this.curSectionList)
        const question = this.questionList[this.curIndex]
        const params = {
          questionId: question.id,
          teachingMaterialId: this.$store.state.branchImport.searchData.teachingMaterialId,
          coordinateInfo: this.getQuestionCoordinateInfo(this.curSectionList, question.id)
        }

        // 小题坐标
        if (question.isCombination) {
          params.subtopicCoordinates = question.subtopicQuestion.map(sub => {
            return {
              questionId: sub.questionId,
              coordinateInfo: this.getQuestionCoordinateInfo(this.curSectionList, sub.questionId)
            }
          })
        }

        this.loading = true
        questionSaveCoordinate([params]).then(res => {
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.setQuestionCoordinate(question, params)
          } else {
            this.$message.error(res.msg)
          }
        }).finally(() => {
          this.loading = false
        })
      }).catch(() => {})
    },

    // 当前选区保存到题目中
    setQuestionCoordinate (question, coordinateData) {
      question.coordinate = coordinateData.coordinateInfo
      if (coordinateData.subtopicCoordinates) {
        coordinateData.subtopicCoordinates.forEach(m => {
          question.subtopicQuestion.find(sub => {
            if (sub.questionId === m.questionId) {
              sub.coordinate = m.coordinateInfo
              return true
            }
          })
        })
      }
    },

    // 翻页事件
    getPageNumUrl (pageInfo) {
      const { no, url } = pageInfo
      this.pageCurrent = no
      this.curPage = pageInfo
      // 排除当前选中题目
      const questionList = this.questionList.filter((m, index) => index !== this.curIndex)
      const sections = this.pageCoordinateDate(no, questionList, this.curSectionList)
      this.$refs.sectionPicker.pageRender(no, url, sections)
    },

    // 构建 页面-选区 数据
    pageCoordinateDate (pageId, questionList, curSectionList) {
      const result = []
      questionList.forEach(question => {
        const title = question.titleNumber || ''
        question.coordinate && question.coordinate.forEach(c => { // c: { 页ID, 选区列表 }
          if (c.page === pageId) {
            c.coordinate.forEach(coordinate => {
              result.push({
                label: title,
                data: {
                  question,
                  isLabel: question.isLabel,
                },
                coordinate,
              })
            })
          }
          // 小题选区
          if (question.isCombination && question.subtopicQuestion) {
            question.subtopicQuestion.forEach(sub => {
              sub.coordinate && sub.coordinate.forEach(m => {
                if (m.page === pageId) {
                  m.coordinate.forEach(coordinate => {
                    result.push({
                      label: title,
                      data: {
                        question,
                        isLabel: question.isLabel,
                      },
                      coordinate
                    })
                  })
                }
              })
            })
          }
        })
      })
      // // 加载临时选区 (翻页至有新选区页面）
      // curSectionList.forEach(c => {
      //   const question = this.questionList[this.curIndex]
      //   if (c.pageId === pageId) {
      //     result.push({
      //       isActive: true,
      //       label: question.titleNumber || '',
      //       data: question,
      //       coordinate: c.coordinate,
      //     })
      //   }
      // })
      return result.concat(curSectionList.filter(m => m.page === pageId))
    },

    // 根据课时id获取所有题目
    getQuestionListByClassHourId (classHourId, materialId) {
      this.loading = true
      return getQuestionListByClassHourId(classHourId, materialId).then(res => {
        if (res.code === 0) {
          this.questionList = res.data
        } else {
          this.$message({ type: 'error', message: res.msg })
        }
      }).catch(err => {
        this.$message({ type: 'error', message: err })
      }).finally(() => {
        this.loading = false
      })
    },

    // 处理坐标数据
    handlerPosition (point) {
      return {
        x_min: +point[0].x.toFixed(2),
        y_min: +point[0].y.toFixed(2),
        x_max: +point[2].x.toFixed(2),
        y_max: +point[2].y.toFixed(2)
      }
    },

    // 获取图片列表
    getQuestionBankImage (teachingMaterialId, standardTeachingMaterialId) {
      this.loading = true
      return getQuestionBankImage(teachingMaterialId, standardTeachingMaterialId).then(res => {
        if (res.code === 0) {
          this.pageList = res.data.sort((a, b) => a.page - b.page)
          // if (this.pageList && this.pageList.length) {
          //   this.getAreaImage(this.pageList[0].questionImage)
          // }
        } else {
          this.$message({ type: 'error', message: res.msg })
        }
      }).finally(() => {
        this.imgLoading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container{
  box-sizing: border-box;
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding: 10px;
  // 滚动条样式
  ::-webkit-scrollbar{
    width: 5px;
    height: 5px;
  }
  // 滚动条内部滑块样式
  ::-webkit-scrollbar-thumb{
    border-radius:10px;
    -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,.3);
    background-color:#F8F8F8;
  }
  .left{
    width: calc(100vw / 2 - 45px);
    padding: 15px;
    background-color: #fff;
    margin-right: 10px;
    border-radius: 4px;
    height: 88vh;
    .header-title{
      font-size: 14px;
      font-weight: 600;
      color: #000;
      margin-bottom: 20px;
    }
    .question-list{
      height: calc(88vh - 40px);
      overflow: auto;
      margin-right: -15px;
      padding-right: 15px;

      .qs{
        margin-bottom: 10px;
        &:nth-last-child(1){
          margin-bottom: 0;
        }
      }
      .nomoredata{
        text-align: center;
        padding: 5px 0;
        font-size: 13px;
        color: #999;
      }
    }
  }
  .right{
    width: calc(100vw / 2 - 45px);
    height: 88vh;
    padding: 15px;
    background-color: #fff;
    border-radius: 4px;
    .header-title-btn{
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 20px;
      div{
        &:nth-child(1){
          font-size: 14px;
          font-weight: 600;
          color: #000;
        }
      }
    }
    .imgArea{
      width: calc(100vw / 2 - 45px);
      height: calc(88vh - 52px);
      .imgBox{
        height: calc(100% - 60px);
        position: relative;
        overflow: scroll;
      }
      .pageNumBox{
        height: 55px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
      }
    }
  }
  /deep/ .el-button--primary{
    background-color: #18C2C2;
    border: 1px solid #18C2C2;
    letter-spacing: 1px;
    width: 80px;
  }
}
</style>
