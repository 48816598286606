<template>
  <div class="question-stem" :class="{ selected: selected }" @click="handSelect">
    <div class="tag">
      <div
        class="drawcoored"
        v-if="hasCoordinate"
        :title="`第${value.coordinate[0].page}页`"
        @click="handleChange"
      >
        <span>已画坐标</span>
      </div>

      <template v-if="selected">
        <el-radio-group
          class="sort-container"
          :value="selectedQuestionId"
          @click.stop="noop"
          @input="changeSubtopic"
        >
          <el-radio class="section-menu" :label="value.id">大题</el-radio>
          <template v-if="value.isCombination">
            <!-- 确保小题排序正确！ -->
            <el-radio
              class="section-menu"
              v-for="(item, index) in value.subtopicQuestion"
              :key="item.questionId"
              :label="item.questionId"
              :value="item.questionId"
            >第{{ index + 1 }}小题</el-radio>
          </template>
        </el-radio-group>
      </template>
    </div>

    <div class="content">
      <div class="titleNumber">{{ value.titleNumber }}</div>
      <ui-mathpad :value="value.stem" />
      <template v-if="value.isCombination">
        <div
          v-for="(item, index) in subtopicQuestion"
          :key="item.questionId"
        >
          <div class="titleNumber">({{ index + 1 }})</div>
          <ui-mathpad :value="item.stem" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    selected: {
      type: Boolean,
      default: false
    },
    selectedQuestionId: {
      type: String,
      default: null
    },
  },
  computed: {
    // 是否已画坐标
    hasCoordinate () {
      const q = this.value
      if (q && q.coordinate && q.coordinate.length) {
        // 组合题验证小题坐标完整
        if (q.isCombination) {
          const s = q.subtopicQuestion
          for (let i = 0; i < s.length; i++) {
            const c = s[i].coordinate
            if (!c || (c.length === 0)) {
              return false
            }
          }
        }
        return true
      }
      return false
    },
    subtopicQuestion () {
      const val = this.value
      if (val && val.isCombination) {
        return val.subtopicQuestion.sort((a, b) => a.sort - b.sort)
      }
      return []
    }
  },
  methods: {
    handleChange () {
      this.$emit('pageChange', this.value.coordinate[0].page)
    },

    handSelect () {
      if (!this.selected) {
        this.$emit('select', this.value)
      }
    },

    noop (e) {
      console.log('=== noop', e)
    },
    // 切换大小题
    changeSubtopic (e) {
      this.$emit('update:selectedQuestionId', e)
      this.$emit('change', e)
    }
  }
}
</script>

<style lang="scss" scoped>
.question-stem {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 15px 5px;
  border: 1px solid #eee;
  border-radius: 4px;
  line-height: 1.5em;

  height: 76px;
  min-height: 76px;
  box-sizing: border-box;
  transition: height 0.3s;

  .content {
    //margin-right: 80px;
    width: calc(100% - 120px);
    height: 100%;
    overflow: hidden;
  }

  .tag {
    font-size: 18px;
    //position: absolute;
    //top: 0;
    //right: 5px;
    color: #18C2C2;
    width: 100px;
    padding: 0 3px;
    margin: -10px 0;
    overflow: hidden;
  }

  // 已选中样式
  &.selected {
    height: auto;
    border: 1px solid #18C2C2;
    background-color: #E8F9F9;
    box-shadow: 0 0 5px rgba(0, 0, 0, .5);
    z-index: 9;
  }
}
.question-stem:hover{
  border: 1px solid orange;
  cursor: pointer;
}

.drawcoored{
  margin-bottom: 10px;
  text-align: right;
  span{
    font-size: 12px;
    padding: 1px 10px;
    background-color: #FF5500;
    color: #fff;
    border-radius: 2px;
  }
}

.titleNumber {
  float: left;
  padding: 0 3px;
  min-width: 26px;
  text-align: center;
  transform: translateY(-2px);
  color: #18C2C2;
}
// 大小题 - 排序
.sort-container {
  display: flex;
  flex-direction: column;

  .section-menu {
    margin: 0 0 5px;
  }
}
</style>
